.facilitypage-main-container{
    min-height: calc(100vh - 80px);
    width : 100vw;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto; /* Allow vertical scrolling */ 
-ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.facility-card-wrapper2{
    width: 200px;
    height: auto;
    background-color: var(--white-color);
    border-radius: 10px;
}


.facilities-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;

}.fp-facilities-filters{
    width: 100%;
    height: 200px !important;
}





.facilitypage-main-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }







