body {
  margin: 0 !important ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* index.css */

body, html, #root {
  margin: 0px !important;
  padding: 0px !important;
  min-height: 100vh !important;
  height: auto !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(46, 57, 80) !important;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 100% !important;
}




