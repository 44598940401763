/* customAuthStyles.css */
.authenticator-glassmorphism {
    --amplify-font-family: 'Roboto', sans-serif !important;
    --amplify-primary-color: #000 !important;
    --amplify-primary-tint: rgba(255, 255, 255, 0.15) !important;
    --amplify-primary-shade: rgba(0, 0, 0, 0.1) !important;
    --amplify-secondary-color: rgba(255, 255, 255, 0.15) !important;
    --amplify-secondary-tint: rgba(255, 255, 255, 0.3) !important;
    --amplify-secondary-shade: rgba(0, 0, 0, 0.3) !important;
    --amplify-background-color: rgba(255, 255, 255, 0.15) !important;
    --amplify-box-shadow: 0 4px 6px var(--amplify-primary-shade) !important;
    --amplify-border-radius: 12px !important;
    --amplify-text-color: #131212 !important;
    --amplify-input-border-color: rgba(255, 255, 255, 0.3) ;
    --amplify-input-focus-border-color: rgba(255, 255, 255, 0.3)  ;
    --amplify-components-input-focus-border-color: rgba(255, 255, 255, 0.3) ;
    --amplify-components-input-color: rgba(255, 255, 255, 0.7) !important;
    --amplify-components-field-label-color: rgba(255, 255, 255, 0.7) !important;
    --amplify-components-fieldcontrol-focus-box-shadow: none !important;
z-index: 11 !important;
position: absolute !important;
top:auto;
left: auto;

  }



  /* glassmo */
 div[data-amplify-router] {
  background-color: black !important;
  color: black !important;
  background: rgba(255, 255, 255, 0.15) !important;
  backdrop-filter: blur(8px) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

input::placeholder{
    color:rgba(255, 255, 255, 0.2) !important;
}
.amplify-heading--3{
    color:rgba(255, 255, 255, 0.7) !important;
}
.amplify-input:focus{
    box-shadow: none !important;
    border: 1px solid rgba(255, 217, 0, 0.479) !important;
  }

  /* SIGN IN BTN */
 
  .amplify-button.amplify-field-group__control.amplify-button--primary.amplify-button--fullwidth{
    background-color: rgba(39, 44, 39, 0) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    color: rgba(255, 255, 255, 0.7) !important;
}
.amplify-button.amplify-field-group__control.amplify-button--primary.amplify-button--fullwidth:hover{
    background-color: rgba(255, 255, 255, 0.2) !important; 
    outline: none !important; 
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}


/* FORGOT BTN */

.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small {
    color: rgba(255, 217, 0, 0.479) !important;
    width: inherit !important;
}
.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small:hover{
    color: rgba(255, 217, 0, 0.479) !important;
    background-color: rgba(255, 255, 255, 0.2) !important; 
}


/* BACK TO SIGN IN BTN */
 button.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small{
    color: rgba(255, 217, 0, 0.479) !important;
}
button.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small:hover{
    color: rgba(255, 217, 0, 0.479) !important;
    background-color: rgba(255, 255, 255, 0.2) !important; 
}


/* SEND CODE BTN */
button.amplify-button.amplify-field-group__control.amplify-button--primary{
    background-color: rgba(39, 44, 39, 0) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    color: rgba(255, 255, 255, 0.7);
}
button.amplify-button.amplify-field-group__control.amplify-button--primary:hover{
    background-color: rgba(255, 255, 255, 0.2) !important; 
    outline: none !important; 
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}


/* EYE BTN */
#root > div > div > div.routeCont > div > div > div > div > div > div > form > div > fieldset > div.amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield > div > div.amplify-field-group__outer-end > button {
    background-color: rgba(255, 255, 255, 0) !important; 
    color: rgba(255, 255, 255, 0.7) !important;
  }
  #root > div > div > div.routeCont > div > div > div > div > div > div > form > div > fieldset > div.amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield > div > div.amplify-field-group__outer-end > button:hover,
  #root > div > div > div.routeCont > div > div > div > div > div > div > form > div > fieldset > div.amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield > div > div.amplify-field-group__outer-end > button:focus {
     background-color: rgba(255, 255, 255, 0.2) !important; 
    outline: none !important; 
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
  }



  
  