.map-popup-card-main {
    width: 200px !important;
    height: 200px !important;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 10px;
    margin: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white !important; 
}



.map-popup-card-main .map-popup-card-title { 
    font-size: 14px !important;
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.5) !important;
    margin-bottom: 1px !important;
}

.map-popup-card-main .map-popup-card-subtitle { 
    font-size: 12px !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.5) !important;
    margin-bottom: 5px !important;
    word-wrap: break-word;  
    overflow-y: auto;
    max-height: 40px; /* Adjust this value as needed */
}

.chip-style {
    font-size: 0.7em !important;
    height: 16px !important;
}

.chip-first {
    margin-right: 10px;
}

.chip-csm {
    background-color: rgb(208, 224, 112) !important;
}

.chip-l4 {
    background-color: rgb(90, 162, 221) !important;
}

.chip-tmp {
    background-color: rgb(221, 90, 90) !important;
}

.map-popup-card-buttons {
    width: 100% !important;
    height: 30px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px !important;
    color: var(--white-color) !important;
}

.map-popup-card-chip-container {
    margin-bottom: 5px !important;
    width: 100% !important;
    height: 20px !important;
}

.detailsModal {
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: white; 
    box-shadow: 24px;
    border-radius: 10px !important;
}

.popup-card-nameaddress-container {
    max-height: 160px !important;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 6px;
    overflow-y: auto;
  
}
