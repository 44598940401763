.cavl-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100px;
    color: white;
    margin-bottom: -10px;
  }
  .cavl-logo {
    width: 80px;
    cursor: pointer;
    margin-bottom: -7px;
  }
  
  .cavl-logo2 {
    width: 120px;
    cursor: pointer;
  }
  
  
  .cavl-text-shine {
    
    padding: 2px 48px;
    color: #ffffff;
    background: linear-gradient(to right, white 0%, var(--valeo-color) 10%, white 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    font-size: 26px;
    text-decoration: none;
    white-space: nowrap;
    height: fit-content;
    line-height: 20px;
    transition: background-position 0s;
    
    &:hover {
      animation: shine 5s infinite linear;
      animation-fill-mode: forwards;
    }
  }
  
  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  








  