.uploadpage-main-container {
  height: calc(100vh - 80px);
  width : 100vw;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
}

.uploadpage-container {
  height: 100%;
  width : 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
padding: 20px;
}

.uploadpage-select-btn{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
 // border: 1px solid rgb(255, 255, 255) !important;
  align-items: center;
  border-radius: 6px !important;
  box-shadow:  0px 0px 2px 4px var(--darkblue-color);
  color: var(--white-color) !important;
  padding: 20px !important;
 // border: 1px solid white !important;
}


.uploadpage-loader-container{
  position: relative;
}

.uploadpage-upload-btn{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
 // border: 1px solid rgb(255, 255, 255) !important;
  align-items: center;
 
  box-shadow: none;
  color: var(--white-color) !important;
  padding: 2px !important;

}

.uploadpage-upload-icn{
  color: var(--white-color) !important;
  font-size: 2em !important;
  height: 50px !important;
  width: 50px !important;
}

.disabled-btn{
  cursor: not-allowed !important;
}
.disabled-icn{
  opacity: 0.2 !important;
}



.uploadpage-back-icn{
  color: var(--white-color) !important;
  font-size: 2em !important;
  height: 50px !important;
  width: 50px !important;
}
.uploadpage-select-icn{
  color: var(--white-color) !important;
  font-size: 2em !important;
  height: 50px !important;
  width: 50px !important;
}

.uploadpage-upload-btn-text{
  line-height: 20px;
  font-size: 15px !important;
  padding: 0px !important;
  color: var(--white-color) !important;
}

.selectfiles{
  margin-top: 2px !important;
}

.uploadfiles{
  margin-top: -14px !important;
}

.uploadpage-mid-container{
  width: 100%;
  height: 80%;
  border: 1px solid green
}


.uploadpage-text-m{
font-size: 14px;
color: var(--white-color);
}

.uploadpage-text-s{
  font-size: 12px;
  color: var(--white-color);
}



.uploadpage-btn-container{
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}


.table-cell-invalid-msg{
  color: red !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.table-cell-valid-msg{
  color: var(--white-color) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.table-cell-valid-icn{
color: green !important;
}
.table-cell-invalid-icn{
  color: red !important;
}

.uploadpage-table-cell{
  color: var(--white-color) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.uploadpage-table{
  color: var(--white-color) !important;
  
  border-radius: 8px !important;
  box-shadow: 0px 0px 2px 4px var(--darkblue-color);

}

