.csa-snackbar{
    position:fixed !important;
    top: 0px !important;
left: 0px !important;
height: fit-content !important;
width: 100% !important;
border-radius: 10px !important;
border: 5px solid rgba(0, 0, 0, 0) !important;
}



.csa-alert{
    width: 100% !important;
}

.csa-bg-green{
    background-color: var(--valeo-color) !important;
}
.csa-border-green{
    border: 3px solid var(--valeo-color) !important;
}

.csa-bg-red{
    background-color: #fab1b1 !important;
}
.csa-border-red{
    border: 1px solid #fab1b1 !important;
}
