/* src/components/ModelViewer.css */
.canvas-container {
    height: 100% !important;
    width: 100% !important;
 
  }

  .btn-center-axis{
    position: absolute;
    top: 90px !important;
    left: 20px !important;
    z-index: 1000 !important;
    border-radius: 5px;
    color: white;
    background-color: var(--darkblue-color) !important;
    width: 140px !important;
    height: 60px;
    box-shadow: none !important;
    border: none !important;
  }


  .dexx{
    background-color: white !important;
  }