.ffilters-main-container{
    height: 170px !important;
    width : inherit !important;
    margin: 0px !important;
    padding: 0px !important;

    flex-wrap: wrap;
background-color: transparent !important;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
margin-bottom: 10px !important;
}

.span-lbls{
    font-size: 1em;
    font-weight: 600;
    color: var(--white-color) !important
}

.facility-name-autocomplete, .facility-id-autocomplete{
width : 150px;
margin-left: 10px;
}

.facility-id-autocomplete, .facility-name-autocomplete{
    width : 150px !important;
    margin-left: 10px;

}

/* Custom scrollbar styles */
*::-webkit-scrollbar {
    width: 8px;
  }
  
  *::-webkit-scrollbar-track {
    background-color: var(--darkblue-color);  /* Scrollbar track color */
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--white-color);; /* Scrollbar thumb color */
    border-radius: 4px;
  }
  
  * {
    scrollbar-color: var(--white-color) var(--darkblue-color); /* For Firefox */
    scrollbar-width: thin; /* For Firefox */
  }



  .ffilters-text-1{
    font-size: 1em;
    font-weight: 600;
    color: var(--white-color) !important
  }

  .search-icon-default{
    color: var(--white-color) !important;
  }
  .search-icon-disabled{
    color: rgb(248, 111, 111) !important;
  }


  .input-msg-red{
    color: rgb(248, 111, 111) !important;
    font-size: 1em;
    font-weight: 600;
  }

  .input-msg-default{
    color: var(--white-color) !important;
    font-size: 1em;
    font-weight: 600;
  }

.ff-chip-container{
  background-color: transparent !important;
  color: var(--white-color) !important;
  border: 1px solid var(--white-color) !important;
}
.ff-chip-main-container{
 
}

.ff-chip-delete-icon{
  color: var(--white-color) !important;
}


.ffilters-container-1{
margin-top: 10px !important;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
;
}