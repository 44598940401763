/* App.css */
@import './styles/customAuthStyles.css';


.routeCont{
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.vlogoXl{
width: 400px !important;
height: 200px !important;
  opacity: 0.2 !important;
}

.main{
  min-height: 100% !important;
  width: 100% !important;
  margin: 0px !important;
}

 